<template>
  <!-- 付款审批 -->
  <div class="payment-approver-page-box">
    <!-- 搜索筛选 -->
    <FormSearch :getdata="getdata" :form-inline="formInline" :form-item-arr="formItemArr" />
    <!-- 表格  分页 -->
    <div class="page-container-table">
      <Table
        :item-data="itemData"
        :list-data="listData"
        :loading="loading"
        :operation-button="operationButton"
        :operation-button-width="192"
      />
      <!-- 分页 -->
      <Pagination :params="formInline" :total="total" :in-article="listData.length" :get-data-list="getdata" />
    </div>
    <!-- 审核组件 -->
    <Audit ref="auditBox" :audit-option-item="auditOptionItem" @reviewSubmitted="reviewSubmitted" />
    <!-- 详情弹出层 -->
    <el-dialog
      title="详情"
      :visible.sync="detailsVisible"
      width="62%"
      class="dialog"
      :close-on-click-modal="false"
      @click="detailsVisible = false"
    />
  </div>
</template>

<script>
import FormSearch from '@/components/FormSearch'
import Table from '@/components/Table'
import Audit from '@/components/Audit'
import Pagination from '@/components/Pagination2'
import { auditInfoQueryByPage, auditInfoAudit } from '@/api/generationMining'
import { downloadFile2 } from '@/utils/util'
// import { getResourceByTask } from '@/api/common'
export default {
  components: { FormSearch, Table, Pagination, Audit },
  data() {
    return {
      detailsVisible: false,
      cmpNameArr: [],
      editFormData: {},
      auditOptionItem: [],
      auditDetails: {},
      itemData: [
        { label: '审批编号', prop: 'auditNo', width: 180 },
        {
          label: '业务类型',
          prop: 'busiType',
          width: 180,
          child: [
            { label: '授信审批', value: 1 },
            { label: '合同审批', value: 2 },
            { label: '垫资方付款', value: 3 },
            { label: '提货单', value: 4 }
          ]
        },
        { label: '业务编号', prop: 'busiNo', width: 180, operation: this.skip },
        { label: '发起人', prop: 'operName', width: 120 },
        { label: '联系电话', prop: 'linkPhone', width: 120 },
        { label: '发起时间', prop: 'createTime', width: 120 },
        { label: '备注', prop: 'reason', width: 140 },
        {
          label: '状态',
          prop: 'auditStatus',
          width: 100,
          child: [
            { label: '付款审批1', value: 1 },
            { label: '付款审批2', value: 2 },
            { label: '付款审批3', value: 3 },
            { label: '已驳回', value: -1 },
            { label: '通过', value: 99 }
          ]
        }
      ],
      formItemArr: [
        {
          type: 'select',
          label: '业务类型',
          value: 'busiType',
          pLabel: 'label',
          pValue: 'value',
          child: [
            { label: '授信审批', value: 1 },
            { label: '合同审批', value: 2 },
            { label: '垫资方付款', value: 3 },
            { label: '提货单', value: 4 }
          ]
        }
      ],
      loading: false,
      total: 0,
      listData: [],
      operationButton: [
        {
          bType: 'primary',
          label: '查看凭证',
          handleEvent: this.viewCredentials,
          child: [{ val: 'approvalPayment' }]
        }
      ],
      formInline: {
        pageSize: 10,
        pageNum: 1
      }
    }
  },
  mounted() {
    this.init(this.$route.path)
  },
  beforeRouteLeave(to, from, next) {
    this.init(to.path)
    next()
  },
  methods: {
    // 查看凭证
    viewCredentials({ fileUrl, fileName }) {
      if (!fileUrl) {
        this.$message.error('文件错误')
        return
      }
      const arr = fileUrl.split('.')
      if ('.pdf .jpg.jpeg.png'.indexOf(arr[1]) >= 0) {
        window.open(this.$fileUrl + fileUrl)
        return
      }
      if ('.doc .docx'.indexOf(arr[1]) >= 0) {
        downloadFile2(this.$fileUrl + fileUrl, fileName, arr[1])
        return
      }
    },
    // 获取文件
    getFile(file) {
      this.auditDetails.fileUrl = file.fileUrl
      this.auditDetails.fileName = file.fileName
    },
    skip(row) {
      switch (row.busiType) {
        case 1:
          this.$router.push({
            path: '/crmManagement/creditDetails',
            query: { id: row.busiId, index: 6 }
          })
          break
        case 2:
          this.$router.push({
            path: '/salesContractManagement/salesContractDetails',
            query: { rowId: row.busiId }
          })
          break
        case 3:
          this.$router.push({
            path: '/salesContractManagement/salesContractDetails',
            query: { rowId: row.busiId }
          })
          break
        case 4:
          this.$router.push({
            path: '/pickUpGoods/pickUpGoodsDetails',
            query: { id: row.busiId }
          })
          break
      }
    },
    // 初始化的时候判断
    init(path) {
      this.formInline = {
        pageSize: 10,
        pageNum: 1,
        cmpRole: '04'
      }
      let flag = false
      switch (path) {
        case '/cooperativeOfficeOA/sealApprover':
          this.formInline.auditType = 1
          this.formItemArr[0].child = [
            { label: '授信审批', value: 1 },
            { label: '合同审批', value: 2 },
            { label: '提货单', value: 3 }
          ]
          this.itemData.forEach((item, index) => {
            if (item.label === '付款金额') {
              this.itemData.splice(index, 1)
            }
          })
          break
        case '/cooperativeOfficeOA/paymentApprover':
          this.formInline.auditType = 2
          this.formItemArr[0].child = [
            { label: '合同审批', value: 2 },
            { label: '垫资方付款', value: 3 }
          ]
          this.itemData.forEach((item) => {
            if (item.label === '付款金额') {
              flag = true
            }
          })
          if (!flag) {
            this.itemData.splice(2, 0, {
              label: '付款金额',
              prop: 'busiAmount',
              width: 180,
              type: 'money'
            })
          }
          break
        case '/cooperativeOfficeOA/ladingApproval':
          this.formInline.auditType = 3
          this.formItemArr[0].child = [{ label: '提货单', value: 4 }]
          this.itemData.forEach((item, index) => {
            if (item.label === '付款金额') {
              this.itemData.splice(index, 1)
            }
          })
          break
      }
      // 查字典取他的状态
      this.itemData.forEach((item) => {
        if (item.prop === 'auditStatus') {
          switch (path) {
            case '/cooperativeOfficeOA/paymentApprover':
              item.child = [
                ...this.$store.getters.getDictionaryItem('OA_PAY_STATUS')
              ]
              break
            case '/cooperativeOfficeOA/sealApprover':
              item.child = [
                ...this.$store.getters.getDictionaryItem('OA_ESIGN_STATUS')
              ]
              break
            case '/cooperativeOfficeOA/ladingApproval':
              item.child = [
                ...this.$store.getters.getDictionaryItem(
                  'agent_delivery_audit_status'
                )
              ]
              break
            default:
              break
          }
        }
      })
      this.getdata()
    },
    // 点击审核确定按钮
    reviewSubmitted(obj) {
      this.auditDetails.approveResult = obj.state
      this.auditDetails.reason = obj.remark
      this.auditDetails.applyCode = this.auditDetails.auditNo
      auditInfoAudit(this.auditDetails, () => {
        this.$message.success('成功')
        this.$refs.auditBox.dialogAuditFormVisible = false
        this.getdata(true)
      })
    },
    viewInfo(row) {
      this.$router.push({
        path: '/cooperativeOfficeOA/approvalDetails',
        query: {
          id: row.id,
          activiteId: row.activiteId,
          currentNodeId: row.currentNodeId
        }
      })
    },
    // 获取列表数据
    getdata(type) {
      if (type === 'reset') {
        this.formInline = {
          pageSize: 10,
          pageNum: 1
        }
      } else if (!type) {
        this.formInline.pageNum = 1
      }
      auditInfoQueryByPage(this.formInline, (res) => {
        this.listData = [...res.data.pageData]
        this.listData.forEach((item) => {
          if (
            item.activiteId &&
            item.currentNodeId &&
            [1, 2, 3].includes(parseInt(item.auditStatus))
          ) {
            item.status = true
          }
          if (item.fileUrl && this.$route.path === '/cooperativeOfficeOA/paymentApprover') {
            item.approvalPayment = true
          }
        })
        this.total = res.data.total
      })
    }
  }
}
</script>
